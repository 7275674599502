<!-- (c) 智汇考题 -->
<template>
    <div class="container">
        <template v-if="loading">
            <common-loading></common-loading>
        </template>
        <template v-else>
            <div class="navheader borderbox" style="border-bottom:none;">
                <img
                        class="back"
                        @click="goBack()"
                        src="../../../assets/img/back-b.png"
                        v-if="userPaper.status === 2"
                />
                <div class="title">{{ paper.title }}</div>
                <div class="sum">{{ num+1 }}/{{ questions.length }}</div>
            </div>
            <div class="time" v-if="userPaper.status === 2"></div>
            <div class="time" v-else>
                <van-icon name="clock-o" color="#999" size="18px"/>
                <span class="time-num">{{ remainingTime.hr }}时{{ remainingTime.min }}分{{remainingTime.sec}}秒</span>
            </div>
            <template>
                <div v-for="(question, index) in questions" :key="index">
                    <!--单选-->
                    <question-choice 
                        v-if="num === index && question.question.type === 1" 
                        :num="num + 1" 
                        :question="question.question"
                        :reply="question.answer_contents_rows"
                        :score="question.score"
                        :is-correct="question.is_correct"
                        :is-over="userPaper.status === 2"
                        @update="questionUpdate"></question-choice>
                    <!-- 多选 -->
                    <question-select
                        :num="index + 1"
                        v-else-if="num === index && question.question.type === 2"
                        :question="question.question"
                        :reply="question.answer_contents_rows"
                        :score="question.score"
                        :is-correct="question.is_correct"
                        :is-over="userPaper.status === 2"
                        @update="questionUpdate"></question-select>
                    <!-- 填空 -->
                    <question-input 
                        :num="num + 1"
                        v-else-if="num === index && question.question.type === 3"
                        :question="question.question"
                        :reply="question.answer_contents_rows"
                        :score="question.score"
                        :is-correct="question.is_correct"
                        :is-over="userPaper.status === 2"
                        @update="questionUpdate"></question-input>
                    <!-- 问答 -->
                    <question-qa 
                        :num="index + 1"
                        v-else-if="num === index && question.question.type === 4"
                        :question="question.question"
                        :reply="question.answer_contents_rows"
                        :thumbs="question.thumbs_rows"
                        :score="question.score"
                        :is-correct="question.is_correct"
                        @update="questionUpdate"
                        :show-image="true"
                        :is-over="userPaper.status === 2"></question-qa>
                    <!-- 判断 -->
                    <question-judge 
                        :num="index + 1"
                        v-else-if="num === index && question.question.type === 5"
                        :question="question.question"
                        :score="question.score"
                        :is-correct="question.is_correct"
                        :reply="question.answer_contents_rows"
                        :is-over="userPaper.status === 2"
                        @update="questionUpdate"></question-judge>
                    <!-- 题帽 -->
                    <question-cap
                        :num="index + 1"
                        v-else-if="num === index && question.question.type === 6"
                        :question="question.question"
                        :score="question.score"
                        :show-image="true"
                        :is-correct="question.is_correct"
                        :reply="question.answer_contents_rows"
                        :is-over="userPaper.status === 2"
                        @update="questionUpdate"></question-cap>
                </div>
            </template>
            <div class="navfooter borderbox">
                <div class="item-footer" @click="submitAll()" v-if="userPaper.status === 1">
                    <img src="../../../assets/img/submit.png" alt="">
                    <span>交卷</span>
                </div>
                <div class="item-footer" @click="collectAnswer(question.question_id)" v-else>
                    <template v-if="collects && collects[question.question_id]">
                        <img src="../../../assets/img/star-selected.png" alt="">
                        <span>已收藏</span>
                    </template>
                    <template v-else>
                        <img src="../../../assets/img/star.png" alt="">
                        <span>收藏</span>
                    </template>
                </div>
                <div class="item-footer" @click="showCard()">
                    <img src="../../../assets/img/card.png" alt="">
                    <span>答题卡</span>
                </div>
                <div class="item-footer" @click="prex()">
                    <img src="../../../assets/img/back.png" alt="">
                    <span>上一题</span>
                </div>
                <div class="item-footer" @click="next()">
                    <img src="../../../assets/img/next.png" alt="">
                    <span>下一题</span>
                </div>

                <div v-if="show_card" class="mask">
                    <div class="answersheet-item">
                        <div class="top-box borderbox">
                            <div class="surplus">你还有<strong>{{ surplus }}</strong>道题未做</div>
                            <div class="back" @click="showCard()">
                                <van-icon name="close" size="28px"/>
                            </div>
                        </div>
                        <div class="answers borderbox active-o">
                            <div class="answer-item borderbox" v-if="questions.length > 0">
                                <div class="numberbox">
                                    <div class="num" :class="{active: activeQuestions[item.question_id]}" v-for="(item, index) in questions" :key="index" @click="jump(index)">{{index+1}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="config-btn borderbox" @click="submitAll()" v-if="userPaper.status === 1">
                            <div class="submit">立即交卷</div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import Vue from 'vue';
import { Icon } from 'vant-green';
import { Dialog } from 'vant-green';
import { Toast } from 'vant-green';

Vue.use(Toast);
Vue.use(Icon);

import CommonLoading from "../../../components/common-loading.vue";
import QuestionChoice from "../../../components/question-choice.vue";
import QuestionJudge from "../../../components/question-judge.vue";
import QuestionSelect from "../../../components/question-select.vue";
import QuestionInput from "../../../components/question-input.vue";
import QuestionQa from "../../../components/question-qa.vue";
import QuestionCap from "../../../components/question-cap.vue";

export default{
    components: {
        CommonLoading,
        QuestionChoice,
        QuestionJudge,
        QuestionSelect,
        QuestionInput,
        QuestionQa,
        QuestionCap,
    },
    data() {
        return {
            type: 1,
            show_card: false,
            id: this.$route.query.id || 0,
            pid: this.$route.query.pid || 0,
            remainingTime: {
                all: 0,
                day: 0,
                hr: 0,
                min: 0,
                sec: 0,
            },
            paper: [],
            questions: [],
            question: null,
            userPaper: null,
            timer: null,
            num: parseInt(this.$route.query.num) || 0,
            loading: true,
            collects: null,
            activeQuestions: [],
            typeList: ['','单选题','多选题','填空题','问答题','判断题','题帽题'],
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            var that = this;
            this.loading = true;
            this.$api.Exam.PaperJoinRecord(this.id, this.pid)
                .then((res) => {
                this.loading = false;
                this.paper = res.data.paper;
                this.userPaper = res.data.record;
                this.remainingTime.all = Date.parse(
                    new Date(res.data.record.endedAt.replace(/-/g, "/"))
                );
                let recordQuestions = [];
                res.data.questions.forEach(question => {
                    let data = [];
                    data['answer_content'] = '';
                    data['answer_contents_rows'] = '';
                    question.type_text = this.typeList[question.type];
                    if (question.type == 6) {
                    let questionContent = JSON.parse(question.content);
                    let answerContentRows = [];
                    questionContent.questions.forEach((zQuestion, index) => {
                        answerContentRows.push({
                            'answer': '',
                            'answer_contents_rows': zQuestion.type == 5 ? -1 : '',
                            'is_correct': 3,
                            'score': 0
                        });
                        questionContent['questions'][index]['content_transform'] = {
                            'text': zQuestion.content != null ? zQuestion.content.replace('/<[^<]+>/', "") : '',
                            'images': that.$utils.getImg(zQuestion.content),
                            'iframes': []
                        }
                        if (zQuestion.remark !== undefined) {
                            questionContent['questions'][index]['remark_transform'] = {
                            'text': zQuestion.remark != null ? zQuestion.remark.replace('/<[^<]+>/', "") : '',
                            'images': that.$utils.getImg(zQuestion.remark),
                            'iframes': []
                            }
                        }
                        questionContent['questions'][index]['type_text'] = this.typeList[zQuestion.type];
                        if (zQuestion.type == 3) {
                            let qAnswer = [];
                            let qTransform = [];
                            let zqArrAnswer = JSON.parse(zQuestion.answer);
                            zqArrAnswer.forEach(v => {
                            qTransform.push({
                                's': v.s,
                                'a': res.data.record.status == 2 ? v.a : ''
                            });
                            let s = {s: v.s};
                            qAnswer.push(s);
                            });
                            questionContent['questions'][index]['answer'] = 'v2:::' + JSON.stringify(qAnswer);
                            questionContent['questions'][index]['answer_transform'] = qTransform;
                        }else {
                            let answerContent = that.$utils.setAnswerContent(zQuestion.type, zQuestion.answer, 1);
                            questionContent['questions'][index]['answer'] = res.data.record.status == 2 ? answerContent['answerContent'] : '';
                        }
                    });
                    question.content_transform = {
                        header: questionContent.header,
                        header_transform: {
                            'text': questionContent.header != null ? questionContent.header.replace('/<[^<]+>/', "") : '',
                            'images': that.$utils.getImg(questionContent.header),
                            'iframes': []
                        },
                        'questions': questionContent.questions
                    };
                    question.content = JSON.stringify(question.content_transform);
                    data['answer_contents_rows'] = answerContentRows;
                    }else {
                    question.content_transform = {
                        'text': question.content != null ? question.content.replace('/<[^<]+>/', "") : '',
                        'images': that.$utils.getImg(question.content),
                        'iframes': []
                    };
                    }
                    if (question.type == 3) {
                    let answer_transform = [];
                    let answer = JSON.parse(question.answer);
                    let arrAnswer = [];
                    answer.forEach(v => {
                        answer_transform.push({
                        's': v.s,
                        'a': res.data.record.status == 2 ? v.a : ''
                        });
                        let s = {s: v.s};
                        arrAnswer.push(s);
                    });
                    question['answer'] = 'v2:::' + JSON.stringify(arrAnswer);
                    question['answer_transform'] = answer_transform;
                    }
                    data['question'] = question;
                    data['is_correct'] = 3;
                    data['score'] = 0;
                    if (res.data.answers.length > 0) {
                    res.data.answers.forEach(function(v1) {
                        if (v1.questionId == question.id) {
                        if (question.type == 6) {
                            let arrAnswer = JSON.parse(v1.answerContent);
                            let content = JSON.parse(question.content);
                            let arrQuestion = content['questions'];
                            let answerContentRows = [];
                            let answerContent;
                            arrAnswer.forEach((answer, index) => {
                            if (answer.is_correct != 3) {
                                answerContent = that.$utils.setAnswerContent(arrQuestion[index]['type'], answer['answer_content'], 2);
                            }else {
                                answerContent = {
                                answerContent: '',
                                answerContentRows: ''
                                };
                            }
                            answerContentRows.push({
                                answer: answerContent['answerContent'],
                                answer_contents_rows: answerContent['answerContentRows'],
                                is_correct: answer['is_correct'],
                                score: answer['score']
                            });
                            });
                            data['answer_content'] = JSON.stringify(answerContentRows);
                            data['answer_contents_rows'] = answerContentRows
                        }else {
                            let answerContent = that.$utils.setAnswerContent(question.type, v1.answerContent, 3);
                            console.log(answerContent)
                            data['answer_content'] = answerContent['answerContent'];
                            data['answer_contents_rows'] = answerContent['answerContentRows'];
                        }
                        data['is_correct'] = v1.is_correct;
                        data['score'] = v1.score;
                        }
                    })
                    }
                    if (res.data.record.status == 2) {  
                    if (question.type != 3 && question.type != 6) {
                        let questionContent = that.$utils.setAnswerContent(question.type, question.answer, 4);
                        data['question']['answer'] = questionContent['answerContent']
                    }
                    question.remark_transform = {
                        'text': question.remark != null ? question.remark.replace('/<[^<]+>/', "") : '',
                        'images': that.$utils.getImg(question.remark),
                        'iframes': []
                    };
                    }
                    data['question_id'] = question.id;
                    recordQuestions.push(data);
                }) 
                let normaldata = recordQuestions;
                if (normaldata.length === 0) {
                    this.$message.error("未获取到试题");
                    this.$router.push({
                        name: "AnswerExams",
                    });
                    return;
                }
                let unread = 0;
                normaldata.forEach((item) => {
                    if (!item.answer_content) {
                        unread++;
                    }
                });
                this.questions = normaldata;
                this.question = this.questions[this.num];
                console.log(this.question)
                this.questions.forEach((item) => {
                    if (item.answer_content) {
                        this.$set(this.activeQuestions, item.question_id, true);
                    }
                });
                this.surplus = unread;
                if (this.userPaper.status === 1) {
                    this.timer = setInterval(() => {
                    if (this.flag) {
                        this.timer && clearInterval(this.timer);
                    } else {
                        this.countdown();
                    }
                    }, 1000);
                } else if (this.userPaper.status === 2) {
                    this.getCollectStatus();
                }
            }).catch((e) => {
                this.$message.error(e.message);
            });
        },
        getCollectStatus() {
            let data = this.questions;  
            let ids = [];
            for (let i = 0; i < data.length; i++) {
                ids.push(data[i].question_id);
            }
            this.$api.Exam.Practice.CollectionStatus(ids)
                .then((res) => {
                    let collects = [];
                    res.data.forEach((item) => {
                        collects[item.id] = item.existence;
                    });
                    console.log(collects);
                    this.collects = collects;
                })
                .catch((e) => {
                this.$message.error(e.message);
                });
        },
        //倒计时
        countdown() {
            const end = parseInt(this.remainingTime.all);
            const now = Date.parse(new Date());
            const msec = end - now;
            if (msec < 0) {
                this.flag = true;
                this.workTime = parseInt(this.paper.expired_minutes) * 60;
                this.finish();
                return;
            }
            this.workTime =
                parseInt(this.paper.expired_minutes) * 60 - parseInt(msec / 1000);
            let day = parseInt(msec / 1000 / 60 / 60 / 24);
            let hr = parseInt((msec / 1000 / 60 / 60) % 24);
            let min = parseInt((msec / 1000 / 60) % 60);
            let sec = parseInt((msec / 1000) % 60);
            this.remainingTime.day = day;
            this.remainingTime.hr = hr > 9 ? hr : "0" + hr;
            this.remainingTime.min = min > 9 ? min : "0" + min;
            this.remainingTime.sec = sec > 9 ? sec : "0" + sec;
            const that = this;
            if (min >= 0 && sec >= 0) {
                //倒计时结束关闭订单
                if (min === 0 && sec === 0 && hr === 0) {
                that.flag = true;
                that.finish();
                return;
                }
            }
        },
        questionUpdate(qid, answer, thumbs) {
            this.$api.Exam.PaperSubmitSingle({
                pid: this.paper.id,
                rid: this.userPaper.id,
                answer: Array.isArray(answer) ? '' : answer,
                questionId: qid.toString(),
                arrAnswer: Array.isArray(answer) ? answer : [],
            });
            let arrType = [1,3,4,5];
            if (typeof qid == "string" && qid.indexOf("-") != -1) {
                if (answer === "") {
                    if (thumbs && thumbs.length > 0) {
                        this.$set(
                            this.activeQuestions,
                            qid.substring(0, qid.indexOf("-")),
                            true
                        );
                    } else {
                        this.$set(
                            this.activeQuestions,
                            qid.substring(0, qid.indexOf("-")),
                            false
                        );
                    }
                } else {
                    this.$set(
                        this.activeQuestions,
                        qid.substring(0, qid.indexOf("-")),
                        true
                    );
                    let arrQid = qid.split('-');
                    if (arrQid[2] != undefined) {
                        if (arrType.indexOf(this.questions[this.num].question.content_transform.questions[arrQid[2]].type) != -1) {
                            this.questions[this.num].answer_contents_rows[arrQid[2]].answer_contents_rows = answer;
                        }else if(this.questions[this.num].question.content_transform.questions[arrQid[2]].type == 2) {
                            this.questions[this.num].answer_contents_rows[arrQid[2]].answer_contents_rows = answer.split(",");
                        }
                    }
                }
            } else {
                if (answer === "") {
                    this.$set(this.activeQuestions, qid, false);
                } else {
                    this.$set(this.activeQuestions, qid, true);
                    if (arrType.indexOf(this.questions[this.num].question.type) != -1) {
                        this.questions[this.num].answer_contents_rows = answer;
                    }else if(this.questions[this.num].question.type === 2) {
                        this.questions[this.num].answer_contents_rows = answer.split(",");
                    }
                }
            }
            console.log(this.questions)
            let num = 0;
            for (let i = 0; i < this.activeQuestions.length; i++) {
                if (this.activeQuestions[i]) {
                    num++;
                }
            }
            this.surplus = this.questions.length - num;
        },
        showCard() {
            this.show_card = !this.show_card;
        },
        next()
        {
            if (this.num < this.questions.length - 1) {
                this.num += 1;
            }else {
                this.$message.warning("已经是最后一道题");
            }
            this.question = this.questions[this.num];
        },
        prex()
        {
            if (this.num === 0) {
                this.$message.warning("已经是第一道题");
                this.num = 0;
            }else {
                this.num -= 1;
            }
            this.question = this.questions[this.num];
        },
        jump(num) {
            this.num = num;
            this.show_card = false;
            this.question = this.questions[this.num];
        },
        submitAll() {
            let message = '';
            if (this.surplus !== 0) {
                message = '还有' + this.surplus + '道题未做，确认要交卷吗？';
            }else {
                message = '确认要交卷吗？'
            }
            Dialog.confirm({
                message: message,
                confirmButtonColor: '#3ca7fa',
                confirmButtonText: '确认交卷',
                cancelButtonText: '继续答题',
            })
            .then(() => {
                // on confirm
                Toast.loading({
                    message: '提交中',
                    forbidClick: true
                });
                this.timer && clearInterval(this.timer);
                this.$api.Exam.PaperSubmit(this.paper.id,this.userPaper.id).then((res) => {
                    this.$router.push({
                        name: "AnswerExamsResult",
                        query: {
                            id: this.paper.id,
                            pid: this.userPaper.id
                        },
                    });
                }).catch((e) => {
                    this.$message.error(e.message);
                });
            })
            .catch(() => {
                // on cancel
            });
        },
        collectAnswer(id) {
            if (this.collects[this.question.question_id]) {
                this.$set(this.collects, id, false);
                this.$message.success("已取消收藏");
            } else {
                this.$set(this.collects, id, true);
                this.$message.success("已收藏试题");
            }
            this.$api.Exam.Practice.Collect(id).then(
                () => {}
            );
        },
        goBack() {
            console.log(123)
            this.$router.go(-1);
        },
        finish() {
            if (this.userPaper.status === 1) {
                this.submitHandle();
            }
        },
        submitHandle() {
            this.timer && clearInterval(this.timer);
            this.$api.Exam.PaperSubmit(this.paper.id, {
                    pid: this.userPaper.id,
                }).then((res) => {
                    this.$router.push({
                        name: "AnswerExamsResult",
                        query: {
                            id: this.paper.id,
                            pid: this.userPaper.id
                        },
                    });
                }).catch((e) => {
                    this.$message.error(e.message);
                });
        }
    }
}

</script>
<style lang="less" scoped>
    .time {
        margin-top: 50px;
        font-size: 18px;
        text-align: center;
        .time-num{
            margin-left: 5px;
            font-weight: 400;
            color: #999;
        }
    }
    .borderbox {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }
    .mask {
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        position: fixed;
        background: rgba(0,0,0,.6);
        z-index: 200;
        .answersheet-item{
            width: 100%;
            height: calc(100% - 114px);
            background: #fff;
            border-radius: 12px 12px 0 0;
            position: absolute;
            display: flex;
            flex-direction: column;
            left: 0;
            top: 114px;
            animation: fadeInUp 0.3s ease-in-out forwards;
            .top-box {
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 30px 15px 10px 15px;
                .surplus {
                    width: auto;
                    height: 14px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #999;
                    line-height: 14px;
                }
                .back {
                    width: auto;
                    font-size: 14px;
                    height: 14px;
                    font-weight: 400;
                    color: #666;
                    line-height: 14px;
                }
            }
            .answers {
                width: 100%;
                height: auto;
                margin-bottom: 0;
                position: relative;
                overflow-y: scroll;
                overflow-x: hidden;
                .answer-item {
                    width: 100%;
                    height: auto;
                    margin-top: 10px;
                    padding: 20px 15px 30px 15px;
                    background: #fff;
                    .name {
                        width: 100%;
                        margin-bottom: 30px;
                        height: 18px;
                        font-size: 18px;
                        font-family: PingFangSC-Medium,PingFang SC;
                        font-weight: 500;
                        color: #333;
                        line-height: 18px;
                    }
                    .numberbox {
                        width: 100%;
                        height: auto;
                        display: grid;
                        grid-row-gap: 30px;
                        grid-column-gap: 33px;
                        grid-template-columns: repeat(6,minmax(0,1fr));
                        .num{
                            width: 30px;
                            height: 30px;
                            background: #ccc;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 16px;
                            font-weight: 500;
                            color: #fff;
                        }
                        .active {
                            background: #3ca7fa;
                        }
                    }
                }
            }
            .active-o {
                margin-bottom: 20px;
            }
            .config-btn {
                width: 100%;
                height: auto;
                padding: 15px 15px;
                position: fixed;
                bottom: 0;
                background-color: #fff;
                border-top: 1px solid #e5e5e5;
                .submit{
                    width: 100%;
                    height: 50px;
                    background: linear-gradient(315deg,#33baf7,#1784ed);
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    font-weight: 500;
                    color: #fff;
                    line-height: 16px;
                } 
            }
        }
        @keyframes fadeInUp{
            from{opacity: 0;transform: translateY(100px);}
            to{opacity: 1;transform: translateY(0);}
        }
    }
</style>